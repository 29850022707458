export default [
  {
    path: '/program',
    name: 'edu-programs',
    component: () => import('@/views/Program/List.vue'),
    meta: {
      title: 'navigationDrawer.eduPrograms',
      access: 'private',
    },
  },
  {
    path: '/program/create',
    name: 'program-create',
    component: () => import('@/views/Program/Form'),
    meta: {
      title: 'navigationDrawer.eduPrograms',
      access: 'private',
      permission: 'editProgram',
    },
  },
  {
    path: '/program/:id',
    name: 'program-info',
    component: () => import('@/views/Program/Info'),
    meta: {
      title: 'navigationDrawer.eduPrograms',
      access: 'private',
    },
  },
  {
    path: '/program/:id/edit',
    name: 'program-edit',
    component: () => import('@/views/Program/Form'),
    meta: {
      title: 'navigationDrawer.eduPrograms',
      access: 'private',
      permission: 'editProgram',
    },
  },
  {
    path: '/program/:id/chronology',
    name: 'program-chronology',
    component: () => import('@/views/Program/Form/Chronology.vue'),
    meta: {
      title: 'navigationDrawer.eduPrograms',
      access: 'private',
      permission: 'editEducationProgramChronology'
    }
  }
]
