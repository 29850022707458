const apiVersion = 'api/v1'

export const app = {
  getCompanyHistory: `${ apiVersion }/history/history/`,
  restoreHistoryObject: id => `${ apiVersion }/history/history/${ id }/restore/`,
  library: `${ apiVersion }/library/library/`,
  libraryClone: id => `${ apiVersion }/library/library/${ id }/clone_course/`,
  libraryCourse: courseId => `${ apiVersion }/library/library/${ courseId ? (courseId + '/') : '' }`,
  courseTags: `${ apiVersion }/directory/course_tags/`,
  logoutAction: `${ apiVersion }/accounts/logout/`,
  programTags: `${ apiVersion }/directory/educational_program_tags/`
}

export const auth = {
	totp: {
		create: `${ apiVersion }/accounts/totp/create/`,
		verify: `${ apiVersion }/accounts/totp/verify/`,
		login: `${ apiVersion }/accounts/totp/login/`,
	},
	u2f: {
		login: `${ apiVersion }/accounts/u2f/login/`,
		create: `${ apiVersion }/accounts/u2f/create/`,
	},
	general: {
		login: `${ apiVersion }/accounts/token/`,
	},
	prone: {
		login: '',
	}
}

export const user = {
  getAllSpacesUsers: `${ apiVersion }/accounts/all_spaces_users/`,
  getSpacePositions: `${ apiVersion }/directory/positions/`,
  getSpaceSubdivisions: `${ apiVersion }/directory/subdivisions/`,
  getInfoWithGoogle: `https://www.googleapis.com/oauth2/v1/userinfo`,
  getInfoWithFacebook: `https://graph.facebook.com/me`,
  blockUserInCompany: `${ apiVersion }/accounts/blocking_membership/`,
  convertToken: `${ apiVersion }/auth/convert-token/`,
  setEmailConfirmation: `${ apiVersion }/accounts/email_confirmation/`,
  getUsersListTemplate: `${ apiVersion }/accounts/user_data_template/`,
  userAction: id => `${ apiVersion }/accounts/user/${ id }/`,
  user: `${ apiVersion }/accounts/user/`,
  getProfile: `${ apiVersion }/accounts/user_info/`,
  sleekplanToken: `${ apiVersion }/accounts/sleekplan_token/`,
  registrationCompany: `${ apiVersion }/space/space_registration/`,
  getUserCompany: id => `${ apiVersion }/space/space/${ id ? (id + '/') : '' }`,
  createNewCompany: `${ apiVersion }/space/new_space/`,
  uploadPhotoToCompany: id => `${ apiVersion }/space/space/${ id }/upload_photo/`,
  removePhotoToCompany: id => `${ apiVersion }/space/space/${ id }/remove_photo/`,
  removeUserPhoto: id => `${ apiVersion }/accounts/user/${ id }/remove_photo/`,
  uploadUserPhoto: id => `${ apiVersion }/accounts/user/${ id }/upload_photo/`,
  actionUserListFile: action => `${ apiVersion }/accounts/user_data/${ action }/`,
  passwordReset: `${ apiVersion }/accounts/password_reset/`,
  setNewPassword: `${ apiVersion }/accounts/password_reset/confirm/`,
  changeCurrentCompany: `${ apiVersion }/space/change_current_space/`,
  changeCurrenRole: `${ apiVersion }/accounts/change_current_user_type/`,
  memberships: id => `${ apiVersion }/accounts/memberships/${ id ? (id + '/') : '' }`,
  changePassword: `${ apiVersion }/accounts/change_password/`,
  finishRegistration: `${ apiVersion }/accounts/finish_registration/`
}

export const group = {
  groupAction: id => `${ apiVersion }/study_group/study_group/${ id ? (id + '/') : '' }`,
  groupMembers: `${ apiVersion }/study_group/group_member/`,
  inviteGroupMembers: `${ apiVersion }/accounts/invite_user/`
}

export const program = {
  programs: id => `${ apiVersion }/educational_program/educational_program/${ id ? (id + '/') : '' }`,
  uploadPhoto: id => `${ apiVersion }/educational_program/educational_program/${ id }/upload_photo/`,
  removePhoto: id => `${ apiVersion }/educational_program/educational_program/${ id }/remove_photo/`
}

export const course = {
  cloneCourseEducationMaterial: (courseId, materialId) => `${ 
    apiVersion }/course/course/${ courseId }/educational_material/${ materialId }/clone/`,
  updateMaterialsSequence: courseID => `${ apiVersion }/course/course/${ courseID }/update_materials_sequence/`,
  getCoursesName: `${ apiVersion }/directory/course_names/`,
  getCourseEducationMaterils: `${ apiVersion }/directory/form_names/`,
  dynamicStatistic: courseID => `${ apiVersion }/course/course/${ courseID }/dynamic_statistic/`,
  blockedUserFromCourse: (courseId, objectId) => {
    return `${ apiVersion }/course/course/${ courseId }/blocked_user/${ objectId ? (objectId + '/') : '' }`
  },
	uploadPhoto: courseId => `${ apiVersion }/course/course/${ courseId }/upload_photo/`,
	deletePhoto: courseId => `${ apiVersion }/course/course/${ courseId }/remove_photo/`,
	course: courseId => `${ apiVersion }/course/course/${ courseId ? (courseId + '/') : '' }`,
  leaveCourse: courseID => `${ apiVersion }/course/course/${ courseID }/leave_course/`,
  downloadCourseCertificate: courseID => `${ apiVersion }/course/course/${ courseID }/download_certificate/`,
  appointedForm: `${ apiVersion }/course/appointed_form/`,
  courseForm: (courseId, courseFormId) => {
    return `${ apiVersion }/course/course/${ courseId }/form/${ courseFormId ? (courseFormId + '/') : '' }`
  },
  retakeForm: courseID => `${ apiVersion }/course/course/${ courseID }/retake_form/`,
	questionUploadFiles: questionId => `${ apiVersion }/course/questions/${questionId}/upload_files/`,
	questionRemoveFile: (questionId, fileId) => `${ apiVersion }/course/questions/${ questionId }/remove_file/${ fileId }/`,
	question: questionID => `${ apiVersion }/course/questions/${ questionID }/`,
	updateQuestionsList: `${ apiVersion }/course/update_questions/`,
	passedForm: passedFormId => `${ apiVersion }/course/student_passed_form/${ passedFormId ? (passedFormId + '/') : '' }`,
  courseStatistic: (courseId, statisticId) => {
    return `${ apiVersion }/course/course/${ courseId }/statistic/${ statisticId ? (statisticId + '/') : '' }`
  },
  statisticColumns: (courseId, columnId) => {
    return `${ apiVersion }/course/course/${ courseId }/statistic_columns/${ columnId ? (columnId + '/') : '' }`
  },
  educationMaterial: (courseID, materialID) => {
    return `${ apiVersion }/course/course/${courseID}/educational_material/${ materialID ? (materialID + '/') : '' }`
  },
  educationMaterialUploadFile: (courseID, materialID) => {
    return `api_uploader/v1/course/course/${ courseID }/educational_material/${ materialID }/upload_file/`
  },
  educationMaterialDeleteFile: (courseID, materialID, fileID) => {
    return `api_uploader/v1/course/course/${courseID}/educational_material/${ materialID }/delete_file/${ fileID }/`
  },
  startFormPassing: `${ apiVersion }/course/start_form_passing/`,
  sendFormPassingAnswers: `${ apiVersion }/course/send_student_answers/`,
  unblockEducationMaterial: `${ apiVersion }/course/unblock_material/`,
  coursesStatus: `${ apiVersion }/course/course/status_info_for_student/`
}

export const webinar = {
	webinar: webinarId => `${ apiVersion }/webinar/webinar/${ webinarId ? (webinarId + '/') : '' }`,
  uploadPhoto: courseId => `${ apiVersion }/course/course/${ courseId }/upload_photo/`,
  course: courseId => `${ apiVersion }/course/course/${ courseId ? (courseId + '/') : '' }`,
  courseForm: courseFormId => `${ apiVersion }/course/form/${ courseFormId ? (courseFormId + '/') : '' }`,
  courseCategory: courseCategoryId => {
      return `${ apiVersion }/directory/course_category/${ courseCategoryId ? (courseCategoryId + '/') : '' }`
  }
}
