export default [
  {
    path: '/library',
    name: 'library',
    component: () => import('@/views/Library/Library'),
    meta: {
      title: 'navigationDrawer.library',
      access: 'private',
      permission: 'viewLibrary'
    }
  },
  {
    path: '/library/:id',
    name: 'library-info',
    component: () => import('@/views/Course/Info'),
    meta: {
      title: 'navigationDrawer.library',
      access: 'private',
      permission: 'viewLibrary'
    }
  }
]
