import WarningIcon from '@/assets/images/icons/notify/warning-circle.svg'
import { checkAccess } from '@/mixins/permissions'

export const checkUnsavedChanges = {
  async beforeRouteLeave (to, from, next) {
    if (this.setBodyState) this.setBodyState()
    if (JSON.stringify(this.initialBodyState) === JSON.stringify(this.body) || this.isSubmit) next()
    else {
      const result = await this.$swal({
        icon: WarningIcon,
        title: this.$t('notify.payAttention'),
        text: this.$t('notify.unsavedChangesWarning'),
        buttons: [this.$t('btn.decline'), this.$t('btn.next')]
      })
      if (result) next()
      else next(false)
    }
  }
}

export const tableActions = {
  methods: {
    actionList (item) {
      const analyticsPage = this.$route.name === 'course-info' && this.$route.hash === '#analytics'
      const courseStudentsPage = this.$route.name === 'course-info' && this.$route.hash === '#students'
      const courseCouchesPage = this.$route.name === 'course-info' && this.$route.hash === '#coaches'
      const hasNoSameProfileId = this.profile.id !== item.id
      const hasNoSameRole = !item.user_types || !item.user_types?.includes(this.profile.current_login_info.type_user)
      const userTypeRole = (!item.user_types?.some(i => ['manager', 'admin', 'owner'].includes(i))
              && checkAccess('editUserRole')) || !checkAccess('editUserRole')

      return this.actions.reduce((acc, value) => {
        switch (value.action) {
          case 'delete':
            if (courseStudentsPage && item.is_individual_student) acc.push(value)
            else if (courseCouchesPage ||(userTypeRole  && hasNoSameProfileId && hasNoSameRole)) acc.push(value)
            break
          case 'reassign':
            if (analyticsPage && item.student.is_allowed_to_give_retake) {
              value.title = this.$t(`btn.reassign${ item.form_type === 1 ? 'Test' : 'Exam' }`)
              acc.push(value)
            }
            break
          case 'certificate':
            if (analyticsPage && item.can_download_certificate) acc.push(value)
            break
          case 'viewUser':
            if (analyticsPage && checkAccess('viewUser')) acc.push(value)
            break
          case 'edit':
            if ( courseCouchesPage || courseStudentsPage || (userTypeRole && hasNoSameProfileId)) acc.push(value)
            break
          case 'block':
            if (courseStudentsPage || (userTypeRole && hasNoSameProfileId && hasNoSameRole && item.email_verified)) {
              acc.push(value)
            }
            break
          case 'sendCredentials':
            if (item.email_verified) acc.push(value)
            break
          default: acc.push(value)
        }
        return acc
      }, [])
    }
  }
}
