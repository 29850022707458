<template lang="pug">
  i(v-if="url" :style="getIconStyles()").custom-svg-icon
</template>

<script>
export default {
  name: 'CustomSvgIcon',
  props: {
    url: { type: String, default: '' },
    size: { type: [Number, String], default: '20' },
    color: { type: String, default: '#98A2B3' }
  },
  methods: {
    getIconStyles () {
      const url = require(`@/assets/${this.url}.svg`)
      return `--icon-url: url(${url});
       --icon-size: ${this.size}px;
       --icon-color: ${this.color}`
    }
  }
}
</script>

<style lang="sass" scoped>
.custom-svg-icon
  &::before
    width: var(--icon-size)
    height: var(--icon-size)
    display: block
    content: ''
    background: var(--icon-color)
    -webkit-mask-size: cover
    mask-size: cover
    -webkit-mask: var(--icon-url) no-repeat
    mask: var(--icon-url) no-repeat
</style>
