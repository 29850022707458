import api from '@/plugins/api'
import { app } from '@/configs/endpoints'
import { getQueryString } from '@/configs/functions'

const successCodes = [200, 201, 204]


export default {
  state: () => ({
    selectedLibraryCourse: null,
    isLoading: false,
    totalPages: null,
    totalCourses: null,
    courseLibrary: [],
    filter: {
      page: 1,
      page_size: 16
    }
  }),
  mutations: {
    SET_DATA_LIBRARY (state, data) {
      state[Object.entries(data)[0][0]] = Object.entries(data)[0][1]
    }
  },
  actions: {
    async getSelectedCourseLibrary ({ commit }, courseId) {
      commit('SET_DATA_LIBRARY', { isLoading: true })
      return await api.get(app.libraryCourse(courseId)).then(response => {
        if (response?.code === 200) commit('SET_DATA_LIBRARY', { selectedLibraryCourse: response.data })
        return response
      }).finally(() => { commit('SET_DATA_LIBRARY', { isLoading: false }) })
    },
    async getCourseLibrary ({ commit, state }, params) {
      commit('SET_DATA_LIBRARY', { isLoading: true })
      const response = await api.get(app.library + getQueryString(params || state.filter))
      commit('SET_DATA_LIBRARY', { isLoading: false })
      if (successCodes.includes(response?.code)) {
        commit('SET_DATA_LIBRARY', { courseLibrary: response.data.results })
        commit('SET_DATA_LIBRARY', { totalPages: response.data.total_pages })
        commit('SET_DATA_LIBRARY', { totalCourses: response.data.count })
      }
      return response
    },
    async cloneCourseLibrary ({ commit }, id) {
      commit('SET_DATA_LIBRARY', { isLoading: true })
      const response = await api.post(app.libraryClone(id))
      commit('SET_DATA_LIBRARY', { isLoading: false })
      return response
    }
  },
  getters: {
  }
}
