<template lang="pug">
div.transition
  v-data-table(
    :headers="headers"
    :loading="loading"
    :items="items"
    :items-per-page="itemsPerPage || 10"
    :loading-text="$t('general.loading')"
    :no-results-text="$t('general.notFindData')"
    :no-data-text="$t('general.noData')"
    :options="sortOptions"
    :must-sort="isMustSort"
    :custom-sort="customSort"
    :item-class="rowClass"
    hide-default-footer
    @click:row="openDetails").table-border.rounded-lg
    template(#footer)
      Pagination(
        :itemsCounter="{ total: items.length, of: itemsCount }"
        :length="pageCount"
        :isShowPaginationControl="isShowPagination"
        :isShowPaginationInfo="isShowPaginationInfo"
        @changeItemsQuantity="changeQuantity"
        @changeCurrentPage="changePage")

    template(#item.delete="{ item }")
      Tooltip(:text="$t('titleBtn.btnDelete')")
        template(#activator)
          v-btn(icon @click.stop="$emit('delete', item.id)")
            svg-icon(url="images/icons/trash")

    template(#item.educational_program_names="{ item }")
      td {{ item.educational_program_names.join(',') }}

    template(#item.score_explanation="{ item }")
      td {{ $t(item.score_explanation) }}

    template(#item.contacts="{ item }")
      td {{ getContact(item.contacts) }}

    template(#item.form_type="{ item }")
      td {{ $t(directoryByID('formTypes', item.form_type).name) }}

    template(#item.form_name="{ item }")
      td {{ item.name }}

    template(#item.passed_form_status="{ item }")
      td {{ $t(directoryByID('passedFormStatuses', item.passed_form_status).name) }}

    template(#item.certificateAvailability="{ item }")
      Tooltip(v-if="item.can_download_certificate" :text="$t('btn.downloadCertificate')")
        template(#activator)
          div.d-flex.justify-center.pointer
            svg-icon(url="images/icons/download" color="#667085" @click.native.stop="$emit('downloadCertificate', item)")
      span(v-else) -

    template(#item.user_type="{ item }")
      td {{ item.user_type?.filter(item => item !== 'owner').map(role => $t(`roles.${ role }`)).join('/') }}

    template(#item.user_company="{ item }")
      td {{ getUsersCompany(item.spaces, userCompany) }}

    template(#item.status="{ item }")
      v-chip(v-if="item.passed_form_status === 3" :color="'blue_light_50'" small)
        span.blue_light_700--text {{ $t('tableHeaders.assigned') }}
      v-chip(v-else-if="item.passed_form_status === 2" :color="item.passed_successfully ? 'success_50' : 'error_50'" small)
        span(:class="item.passed_successfully ? 'success_700--text' : 'error_700--text'") {{ $t(item.passed_successfully ? 'tableHeaders.passedTime' : 'tableHeaders.notPassed') }}

    template(#item.passed_successfully="{ item }")
      v-chip(v-if="item.passed_form_status === 3" :color="'blue_light_50'" small)
        span.blue_light_700--text {{ $t('tableHeaders.assigned') }}
      v-chip(v-else-if="item.passed_form_status === 2" :color="item.passed_successfully ? 'success_50' : 'error_50'" small)
        span(:class="item.passed_successfully ? 'success_700--text' : 'error_700--text'") {{ $t(item.passed_successfully ? 'tableHeaders.passedTime' : 'tableHeaders.notPassed') }}

    template(#item.blocked_in_course="{ item }")
      v-chip(:color="item.blocked_in_course ? 'error_50' : 'grey_100'" small)
        span(:class="item.blocked_in_course ? 'error_700--text' : 'grey_700--text'") {{ $t(item.blocked_in_course ? 'navigationDrawer.blocked' : 'active') }}

    template(#item.is_online="{ item }")
      v-chip(:color="usersStatuses(item).color" small)
        v-icon(:class="usersStatuses(item).textColor") mdi-circle-medium
        span(:class="usersStatuses(item).textColor").font-weight-500.mr-1 {{ $t(usersStatuses(item).name) }}

    template(#item.editUser="{ item }")
      v-menu(v-if="actions && actions.length" rounded="b-lg" left)
        template(#activator="{ on }")
          v-btn(icon v-on="on")
            svg-icon(url="images/icons/edit" size="16" color="#667085").ml-2
        v-list.pa-0
          v-list-item(
            v-for="action in actionList(item)"
            :key="action.title"
            @click.stop="$emit(action.actionUnblock && item.blocked ? action.actionUnblock : action.action, item || (action.action, item))").pl-4
            v-list-item-icon.mr-3
              svg-icon(:url="`images/icons/${ action.iconUnblock && item.blocked ? action.iconUnblock : action.icon }`" size="16" color="#667085")
            div
              span.fs-14.color-grey__700 {{ (action.titleUnblock && item.blocked ? action.titleUnblock : action.title) || action.title }}

    template(#item.edit="{ item }")
      v-menu(v-if="actions && actionList(item).length" rounded="b-lg" left)
        template(#activator="{ on }")
          v-btn(icon v-on="on")
            svg-icon(url="images/icons/edit" size="16" color="#667085").ml-2
        v-list.pa-0
          v-list-item(v-for="action in actionList(item)" :key="action.title" @click.stop="$emit(action.action, item)").pl-4
            v-list-item-icon.mr-3
              svg-icon(:url="`images/icons/${ action.iconUnblock && item.blocked ? action.iconUnblock : action.icon }`" size="16" color="#667085")
            div.fs-14.color-grey__700 {{ (action.titleUnblock && item.blocked ? action.titleUnblock : action.title) || action.title }}

    template(#item.exams="{ item }")
      Tooltip(:text="item.exams" :disabled="item.exams.length < 30")
        template(#activator)
          div.overflowCell.max-width-200 {{ item.exams }}

    template(#item.tests="{ item }")
      Tooltip(:text="item.tests" :disabled="item.tests.length < 30")
        template(#activator)
          div.overflowCell.max-width-200 {{ item.tests }}

    template(#item.user_full_info="{ item }")
      div.d-flex.align-center
        v-avatar(size="40" color="#EAECF5").mr-3.my-4
          v-img(v-if="item.photo" :src="item.photo" alt="user-photo")
          svg-icon(v-else url="images/icons/avatar" size="25" color="#3E4784")
        div.flex-column.d-flex
          Tooltip(
            :text="`${ item.last_name } ${ item.first_name }`"
            :disabled="`${ item.last_name.toString() }${ item.first_name.toString() }`.length < 30")
            template(#activator)
              div(v-if="item.first_name && item.last_name").fs-14.font-weight-medium.grey_700--text.overflowCell.max-width-200 {{ item.last_name }} {{ item.first_name }}
              div(v-else).fs-14.font-weight-medium.grey_700--text.overflowCell.max-width-200  {{ $t('tableHeaders.invited') }} {{ $t('tableHeaders.user') }}
          Tooltip(
            :text="item.email"
            :disabled="item.email.length < 30")
            template(#activator)
              div.fs-14.grey_500--text.overflowCell.max-width-200 {{ item.email }}
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { usersStatuses } from '@/configs/statuses'
import { getUsersCompany, getContact, tableRowRoute } from '@/configs/functions'
import { tableActions } from '@/mixins'

export default {
  name: 'Table',
  components: {
    Tooltip: () => import('./Tooltip'),
    Pagination: () => import('@/components/layouts/Pagination')
  },
  mixins: [tableActions],
  props: {
    isShowPaginationInfo: { type: Boolean, default: true },
    itemsCount: { type: Number, default: 0 },
    isShowPagination: { type: Boolean, default: true },
    isUseQuery: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    isMustSort: { type: Boolean, default: false },
    pageCount: { type: Number, default: 0 },
    userCompany: { type: Array, default: () => ([]) },
    headers: { type: Array, default: () => ([]) },
    items: { type: Array, default: () => ([]) },
    actions: { type: Array, default: () => ([]) },
    itemsPerPage: { type: Number, default: 0 },
    sortOptions: { type: Object, default: () => ({ sortBy: [], sortDesc: [] }) },
  },
  data () {
    return {
      usersStatuses,
      getUsersCompany,
      getContact
    }
  },
  computed: {
    ...mapGetters(['directoryByID']),
    ...mapState({
      profile: state => state.user.profile
    })
  },
  methods: {
    rowClass (item) {
      if (tableRowRoute(item, this.$route)) return 'pointer'
    },

    changeQuantity (val) {
      this.$emit('changeQuantity', val)
      if (this.isUseQuery) {
        this.$router.replace({ ...this.$route, query: { page: 1 } }).catch(() => {})
        this.$emit('changeCurrentPage', 1)
      }
    },

    openDetails (item) {
      const detailsRoute = tableRowRoute(item, this.$route)
      if (detailsRoute) this.$router.push(detailsRoute)
    },

    changePage (page) {
      this.$router.replace(this.isUseQuery ? { ...this.$route, query: { page: page } } : { query: page })
        .catch(() => {})
      this.$emit('changeCurrentPage', page)
    },

    customSort(items, sortBy, sortDesc) {
      sortBy.length && sortDesc.length && this.$emit('changeSort', { sortBy, sortDesc })
      return items
    }
  }
}
</script>
