export default [
  {
    path: '/course',
    name: 'courses',
    component: () => import('@/views/Course/Courses'),
    meta: {
      title: 'navigationDrawer.courses',
      access: 'private',
    },
  },
  {
    path: '/course/create',
    name: 'course-create',
    component: () => import('@/views/Course/Form'),
    meta: {
      title: 'navigationDrawer.courses',
      access: 'private',
      permission: 'createCourse'
    },
  },
  {
    path: '/course/:id/edit',
    name: 'course-edit',
    component: () => import('@/views/Course/Form'),
    meta: {
      title: 'navigationDrawer.courses',
      access: 'private',
      permission: 'editCourse'
    },
  },
  {
    path: '/course/:id',
    name: 'course-info',
    component: () => import('@/views/Course/Info'),
    meta: {
      title: 'navigationDrawer.courses',
      access: 'private',
    },
  },
  {
    path: '/course/:id/education-materials',
    name: 'course-education-materials',
    component: () => import('@/views/Course/EducationMaterials/EducationMaterials.vue'),
    meta: {
      title: 'navigationDrawer.courses',
      access: 'private',
      permission: 'editEduMaterials'
    }
  },
  {
    path: '/course/:id/education-materials/constructor',
    name: 'course-tests-constructor',
    component: () => import('@/views/Course/Constructor'),
    meta: {
      title: 'navigationDrawer.courses',
      access: 'private',
      permission: 'editEduMaterials'
    },
  },
  {
    path: '/course/:id/education-materials/:materialID',
    name: 'course-education-materials-info',
    component: () => import('@/views/Course/EducationMaterials/EducationMaterialsInfo.vue'),
    meta: {
      title: 'navigationDrawer.courses',
      access: 'private',
      permission: 'viewTestMaterials'
    },
  },
  {
    path: '/course/:id/education-materials/:materialID/form/:formID',
    name: 'course-education-materials-form-passing',
    component: () => import('@/views/Course/FormPassing.vue'),
    meta: {
      title: 'navigationDrawer.courses',
      access: 'private',
      permission: 'startTest'
    },
  },
  {
    path: '/course/:id/education-materials/:materialID/constructor',
    name: 'course-tests-constructor-edit',
    component: () => import('@/views/Course/Constructor'),
    meta: {
      title: 'navigationDrawer.courses',
      access: 'private',
      permission: 'editEduMaterials'
    },
  },
  {
    path: '/course/:id/statistic/:statisticID',
    name: 'course-statistic',
    component: () => import('@/views/Course/Statistic'),
    meta: {
      title: 'navigationDrawer.courses',
      access: 'private'
    }
  }
]
