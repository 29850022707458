import api from '@/plugins/api'
import Vue from 'vue'
import { webinar } from '@/configs/endpoints'
import { getQueryString } from '@/configs/functions'

export default {
    state: () => ({
      isLoad: false,
      webinars: [],
      webinarsCountPages: null,
      webinarsCountTotal: null,
      filter: {
        page: 1,
        page_size: 10
      },
    }),
    mutations: {
      SET_DATA_WEBINAR (state, data) {
        state[Object.entries(data)[0][0]] = Object.entries(data)[0][1]
      },
      DELETE_DATA_WEBINAR (state, data) {
        const param = Object.entries(data)[0]
        state[param[0]] = state[param[0]].filter(item => item.id !== param[1])
      },
      UPDATE_DATA_WEBINAR (state, data) {
        const index = state[Object.entries(data)[0][0]].findIndex(item => item.id === Object.entries(data)[0][1].id)
        Vue.set(state[Object.entries(data)[0][0]], index, Object.entries(data)[0][1])
      },
      ADD_DATA_WEBINAR (state, data) {
        state[Object.entries(data)[0][0]].push(Object.entries(data)[0][1])
      },
    },
    actions: {
      async getWebinars({ commit, state }, params) {
        commit('SET_DATA_WEBINAR', { isLoad: true })
        const response = await api.get(`${ webinar.webinar() }${ getQueryString(params || state.filter) }`)
        if (response?.code === 200) {
          commit('SET_DATA_WEBINAR', { webinars: response.data.results })
          commit('SET_DATA_WEBINAR', { webinarsCountPages: response.data.total_pages })
          commit('SET_DATA_WEBINAR', { webinarsCountTotal: response.data.count })
        }
        commit('SET_DATA_WEBINAR', { isLoad: false })
      },
    },
  }