import api from '@/plugins/api'

export default {
  state: () => ({
    program: {},
    group: {},
    company: {},
    course: {},
    coach: {},
    user: {},
    question: {},
    educationalMaterial: {},
  }),
  mutations: {
    ADD_MEDIA_BY_TYPE(state, data) {
      state[Object.entries(data)[0][0]] = Object.entries(data)[0][1]
    },
  },
  actions: {
    async getMediaFile({ commit, state }, params) {
      if (!state[params.entityType][params.entityId] && params.mediaUrl) {
        try {
          const response = await api.get(params.mediaUrl, {
            responseType: 'blob',
            headers: {
              'content-type': 'image/*',
            },
          })

          response.code === 200 &&
            commit('ADD_MEDIA_BY_TYPE', {
              [params.entityType]: {
                ...state[params.entityType],
                [params.entityId]: URL.createObjectURL(response.data),
              },
            })
        } catch (exception) {
          console.error('GET_MEDIA_FILE_ERROR: ', exception)
        }
      }
    },

    async getCurrentMediaFile(context, params) {
      const response = await api.get(params.mediaUrl, { responseType: 'blob' })
			return response.data
    },

    async getCurrentMediaFileHeaders(context, url) {
      return await api.head(url, { responseType: 'text' })
    }
  }
}
