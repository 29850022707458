import store from '@/store'

const r = {}

export const setRoles = () => {
  const userType = store.state.user.profile?.current_login_info?.type_user
  r.student = userType === 'student'
  r.trainer = userType === 'trainer'
  r.manager = userType === 'manager'
  r.admin = userType === 'admin'
  r.owner = userType === 'owner'
}

export const checkAccess = typeRule => {
  // Recommendation: Arrange the roles according to their importance in each case
  switch (true) {
    case ['editCompany', 'createCompany', 'uploadUsersFile',
      'viewCompanyHistory', 'createCourse', 'deleteCourse', 'deleteProgram', 'createProgram',
      'viewLibrary', 'editEducationProgramChronology', 'editProgram', 'editProgramStudent',
      'viewTestResult', 'editEducationalProgram'].includes(typeRule):
      return r.owner || r.admin
    case ['createGroup', 'editGroup', 'viewUser', 'editUser', 'createUser', 'importUser',
      'deleteCourseFromProgram', 'deleteGroupFromCourse', 'editCourseComponents', 'editCourse',
      'viewCourseCoachesInfo', 'deleteCoachFromCourse', 'openModalGroup'].includes(typeRule):
      return r.owner || r.admin || r.manager
    case ['viewCourseGroups', 'viewCourseAnalytics', 'viewCourseStudents', 'blockStudentInCourse', 'viewGroupStudents',
      'showGroupsInNavigation', 'viewUserInfoInForm', 'viewCourseInfoActions', 'viewUserAllInfo',
      'viewStudentAnalyticsCard', 'viewAnalyticDataFilter', 'viewCoursePage'].includes(typeRule):
      return r.owner || r.admin || r.trainer || r.manager
    case ['startTest', 'viewCourseResults', 'disabledProfileFields', 'viewAnalyticsForStudent',
      'viewGroupMembers', 'viewCourseStatus', 'viewCourseProfile'].includes(typeRule):
      return r.student
    case ['viewTestMaterials'].includes(typeRule):
      return r.owner || r.admin || r.manager || r.student || r.trainer
    case ['leaveCourse','editCourseCoach'].includes(typeRule):
      return r.trainer || r.manager
    case ['editEduMaterials'].includes(typeRule):
      return r.trainer || r.owner || r.admin
    case ['createEducationalProgram', 'editUserRole'].includes(typeRule):
      return r.manager
    case ['showProfileInfoTabs', 'viewCourseTests'].includes(typeRule):
      return r.trainer || r.student
    case ['disabledProfileInfoTabs'].includes(typeRule):
      return r.trainer
    default: return false
  }
}

export const checkUserAccess = loginUser => {
  switch (loginUser) {
    case 'owner': return ['owner']
    case 'admin': return ['owner', 'admin']
    case 'manager': return ['owner', 'admin', 'manager']
    case 'trainer': return ['owner', 'admin', 'trainer', 'manager']
    default: return false
  }
}
