import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import store from '@/store'
import router from '@/router'
import authorization from './plugins/auth'
import api from './plugins/api'
import notify from '@ananasbear/notify'
import validate from '@/plugins/validation'
import i18n from '@/plugins/vue-i18n'
import Table from '@/components/layouts/Table'
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import moment from 'moment'
import VueSwal from 'vue-swal'
import VueSweetalert2 from 'vue-sweetalert2'
import ExpandableImage from '@/components/layouts/ExpandableImage'
import SvgIcon from '@/components/layouts/CustomSvgIcon.vue'
import Tooltip from '@/components/layouts/Tooltip.vue'

Vue.component('Tooltip', Tooltip)
Vue.component('ExpandableImage', ExpandableImage)
Vue.component('Table', Table)
Vue.component('SvgIcon', SvgIcon)
Vue.use(Vuelidate)
Vue.use(VueTheMask)
Vue.use(VueSwal)
Vue.use(VueSweetalert2)

// Styles
import '@/assets/sass/index.sass'
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.config.productionTip = false
Vue.prototype.$notification = notify
Vue.prototype.$api = api
Vue.prototype.$auth = authorization
Vue.prototype.$validate = validate
Vue.prototype.$moment = moment

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
