import { checkAccess } from '@/mixins/permissions'
import moment from 'moment'
import i18n from '@/plugins/vue-i18n.js'
import Vue from 'vue'

// Return route params for history
export const objectModelRouteParams = (model, essence) => {
  const existingObject = model.new_object || model.old_object
  switch (model.object_model) {
    case 'educationalprogram':
      if (model.action === 'delete') return false
      else return { name: 'program-info', params: { id: model.object_id } }
    case 'studygroup':
      if (model.action === 'delete') return false
      else return { name: 'group-info', params: { id: model.object_id } }
    case 'membership':
      if (model.action === 'delete') return false
      else return { name: 'user-info', params: { id: existingObject.user } }
    case 'course':
      if (model.action === 'delete') return false
      else return { name: 'course-info', params: { id: existingObject.id } }
    case 'courseeducationalmaterial':
      if (model.action === 'delete') return false
      else return {
        name: 'course-education-materials-info',
        params: { id: existingObject.course, materialID: existingObject.id }
      }
    case 'user':
      return { name: 'user-info', params: { id: existingObject.id } }
    case 'space':
      return { name: 'company-info', params: { id: existingObject.id } }
    case 'blockedcourseuser':
      return {
        name: essence === 'course' ? 'course-info' : 'user-info',
        params: { id: essence === 'course' ? existingObject.course : existingObject.user }
      }
    case 'form':
      return {
        name: 'course-education-materials-info',
        params: {
          id: existingObject.educational_material.course,
          materialID: existingObject.educational_material.id
        }
      }
    case 'retakeform':
      if (essence === 'user') return { name: 'user-info', params: { id: existingObject.user } }
  }
}

// Return route for table row
export const tableRowRoute = (item, route) => {
  switch (route.name) {
    case 'profile':
    case 'user-info':
      if (route.hash === '#courses') return { name: 'course-info', params: { id: item.id }, hash: '#description' }
      else if (route.hash === '#programs') return { name: 'program-info', params: { id: item.id }, hash: '#generalInfo' }
      else if (route.hash === '#groups') return { name: 'group-info', params: { id: item.id } }
      else if (route.hash === '#exams' && item.passed_form_status === 2) {
        return { name: 'course-statistic', params: { id: item.course, statisticID: item.id } }
      }
      else return false
    case 'edu-programs': return { name: 'program-info', params: { id: item.id }, hash: '#generalInfo' }
    case 'courses': return { name: 'course-info', params: { id: item.id }, hash: '#description' }
    case 'course-info':
      if (route.hash === '#groups') return { name: 'group-info', params: { id: item.id } }
      if (route.hash === '#analytics') return { name: 'course-statistic', params: { statisticID: item.id } }
      if (['#students', '#coaches'].includes(route.hash) && checkAccess('viewUser')) {
        return { name: 'user-info', params: { id: item.id }, hash: '#courses' }
      }
      else return false
    case 'groups': return { name: 'group-info', params: { id: item.id }, hash: '#programs' }
    case 'group-info':
      if (route.hash === '#programs') return { name: 'program-info', params: { id: item.id }, hash: '#generalInfo' }
      if (route.hash === '#courses') return { name: 'course-info', params: { id: item.id }, hash: '#description' }
      if (['#coaches', '#managers', '#students'] && checkAccess('viewUser')) {
        return { name: 'user-info', params: { id: item.student?.id || item.id }, hash: '#courses' }
      }
      else return false
    case 'user':
      if (checkAccess('viewUser')) {
        return { name: 'user-info', params: { id: item.student?.id || item.id }, hash: '#courses' }
      }
      else return false
    case 'library': return { name: 'library-info', params: { id: item.id }, hash: '#description' }
    default: return false
  }
}

// Return file type
export const getFileType = fileName => {
  const type = fileName.split('.').pop()
  switch (true) {
    case ['wmv', 'mpg', 'mpeg', 'mp4', 'mov', 'mkv',
      'm4v', 'flv', 'avi', '3gp', '3g2'].includes(type): return 'Video'
    case ['wav', 'mp3', 'ogg'].includes(type): return 'Audio'
    case ['svg', 'png', 'jpeg', 'jpg', 'gif'].includes(type): return 'Image'
    default: return 'Text'
  }
}

// Sorting the array, first the first format, then the second, then the numbers
export const dataSort = (array, field, lang = 'ua') => {
  const collator = new Intl.Collator(lang)
  return array.slice().sort((a, b) => {
    if(a && !b) return -1
    else if(!a && b) return 1
    return collator.compare(a[field].toLowerCase(), b[field].toLowerCase())
  })
}

//Get info about status of any of the activities
export const compareDate = (date, info) => {
  const startDate = moment(date)
  const nowDate = moment().format()
  if (startDate.isAfter(nowDate)) return info[0]
  if (startDate.isSame(nowDate, 'day')) return info[1]
  if (startDate.isBefore(nowDate)) return info[2]
}

// Get items from first array and set items in second array to id
export const changeItemsBetweenArray = (availableList, assignedList) => {
  return availableList.reduce((acc, mainItem) => {
    if (!assignedList.map(secondItem => secondItem.id).includes(mainItem.id)) acc.push(mainItem)
    return acc}, [])
}

export const getIcon = iconName => {
  const images = require.context('@/assets/images/icons')
  return images(`./${ iconName }.svg`)
}

export const getUsersCompany = (userSpaces, company) => {
  const companyItems = []
  userSpaces.forEach(spaceId => {
    if (company.some(item => item.id === spaceId)) companyItems.push(company.find(item => item.id === spaceId).name)
  })
  const usersCompany = companyItems.filter((company, index) => {
    return companyItems.indexOf(company) === index
  })
  return usersCompany.join(' / ')
}

export const declensionForNumber = (num, dec) => {
  if (dec) {
    if (num > 100) num = num % 100
    if (num <= 20 && num >= 10) return dec[2]
    if (num > 20) num = num % 10
    return num === 1 ? dec[0] : num > 1 && num < 5 ? dec[1] : dec[2]
  }
}

export const getContact = contacts => {
  return contacts.map(item => item.value.replace(/\s/g, '')).join('; ')
}

export const getQueryString = filter => {
  if (!filter) return ''
  const QUERY = new URLSearchParams()
  Object.keys(filter).forEach(queryField => {
    filter[queryField] && QUERY.append(queryField, filter[queryField])
  })
  return `?${ QUERY }`
}

export const downloadBlob = (blob, name) => {
  let link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = `${ name }`
  link.click()
}

export const clearCookies = () => {
  const cookies = document.cookie.split(';')
  for (let cookieIndex = 0; cookieIndex < cookies.length; cookieIndex++) {
    const cookie = cookies[cookieIndex].split("=")
    const domainParts = document.domain.match(/[a-zA-Z0-9]+\.?/g)
    for (let domainIndex = 0; domainIndex < domainParts.length; domainIndex++) {
      let domain = (domainIndex > 0 ? "." : "" ) + document.domain.match(/[a-zA-Z0-9]+\.?/g).splice(domainIndex).join("")
      document.cookie = cookie[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=" + domain + ";";
    }
  }
}

export const changeToken = newToken => {
  sessionStorage.clear()
  clearCookies()
  document.cookie = `TOKEN=Bearer ${newToken}; path=/;`
  localStorage.setItem('TOKEN', `Bearer ${newToken}`)
}

export const getSortOptions = ordering => {
  const options = {
    sortBy: [],
    sortDesc: [],
  }
  if (ordering) {
    const isSortDesc = ordering[0] === '-'
    const sortBy = isSortDesc ? ordering.substr(1) : ordering
    options.sortBy = [sortBy]
    options.sortDesc = [isSortDesc]
  }

  return options
}

export const getSortOrdering = options => {
  let ordering = ''
  if (options.sortBy && Array.isArray(options.sortBy) && options.sortDesc && Array.isArray(options.sortDesc)) {
    ordering = `${ options.sortDesc[0] ? '-' : '' }${ options.sortBy[0] }`
  }

  return ordering
}

export const errorsHandler = (response, icon) => { // Handle errors from requests
  if (!response) return false
  return response.data?.json().then(err => {
    let text
    if (Array.isArray(err)) text = err[0] // If response error is Array
    else { // If response error is Object
      if (err.non_field_errors) text = err.non_field_errors[0]
      else if (err.email) text = err.email[0]
      else if (err.photo) text = err.photo[0]
      else if (err.study_groups) text = err.study_groups[0]
      else if (err.name) text = err.name[0]
      else if (err.new_password) text = err.new_password[0]
      else if (err.old_password) text = err.old_password[0]
    }

    const getTitle = () => {
      switch (text) {
        case 'Educational material with this name already exists': return 'notify.alreadyExistMaterial'
        case 'user with this email already exists.': return 'create.emailAlready'
        case 'No file was submitted': return 'uploadPhoto.notify.requiredFile'
        case 'Max image size is 0.5': return 'uploadPhoto.notify.maxFileSize'
        case 'This list may not be empty.': return 'notify.choseCourseGroup'
        case 'Course with this name already exists': return 'notify.alreadyExistCourse'
        case 'Study group with this name already exists': return 'notify.alreadyExistGroup'
        case 'Educational program with this name already exists': return 'notify.alreadyExistProgram'
        case 'The password did not pass validation': return 'notify.notValidNewPassword'
        case 'This password is entirely numeric': return 'notify.notValidNewPassword'
        case 'Wrong password': return 'notify.checkPassword'
        case 'The password is too similar to the email.': return 'notify.passwordShouldNotBeSimilarToEmail'
        default: return JSON.stringify(err)
      }
    }
    Vue.$swal({
      icon: require(`@/assets/images/icons/notify/${ icon || 'warning-circle' }.svg`),
      title: i18n.t(getTitle())
    })
  })
}

export const fileConverting = file => {  // Convert file to URLObject to get image preview
  const URL = window.URL || window.webkitURL
  if (file?.size && file?.type) return URL.createObjectURL(file)
}

export const downloadFile = (url, fileName = null) => { // Download blob file
  const link = document.createElement('a')
  document.body.appendChild(link)
  link.href = url
  if (fileName) link.download = fileName
  link.dispatchEvent(
    new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
  )
  document.body.removeChild(link)
}

export const bytesToSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return parseFloat((bytes / Math.pow(1024, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const searchPeople = (search , searchItem) => {
  return search.filter(item => {
    return item.email?.toLowerCase().includes(searchItem.toLowerCase()) ||
      item.first_name?.toLowerCase().includes(searchItem.toLowerCase()) ||
      item.last_name?.toLowerCase().includes(searchItem.toLowerCase()) ||
      item.name?.toLowerCase().includes(searchItem.toLowerCase())
  })
}

export const declensionParams = (alt, icon, count, declension) => {
  return {
    icon: icon,
    alt: alt,
    dataCount: count,
    declension: [
      i18n.t(declension+'.declension1'),
      i18n.t(declension+'.declension2'),
      i18n.t(declension+'.declension3'),
      i18n.t(declension+'.declension4'),
    ]
  }
}

export const getDateFormat = date => {
  if (date) return date.split('T')[0].split('-').reverse().join('.')
  else return ''
}

