import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import directory from './modules/directory'
import user from './modules/user'
import group from './modules/group'
import program from './modules/program'
import company from './modules/company'
import course from './modules/course'
import statistic from './modules/statistic'
import media from './modules/media'
import webinar from './modules/webinar'
import library from './modules/library'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    directory,
    user,
    group,
    program,
    course,
    statistic,
    company,
    media,
    webinar,
    library
  }
})

export default store
