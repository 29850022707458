export default [
  {
    path: '/company/create',
    name: 'company-create',
    component: () => import('@/views/Company/CompanyForm.vue'),
    meta: {
      title: 'navigationDrawer.company',
      access: 'private',
      permission: 'createCompany'
    }
  },
  {
    path: '/company/:id',
    name: 'company-info',
    component: () => import('@/views/Company/CompanyInfo'),
    meta: {
      title: 'navigationDrawer.company',
      access: 'private'
    }
  },
  {
    path: '/company/:id/edit',
    name: 'company-edit',
    component: () => import('@/views/Company/CompanyForm.vue'),
    meta: {
      title: 'navigationDrawer.company',
      access: 'private',
      permission: 'editCompany'
    }
  },
  {
    path: '/company/:id/history',
    name: 'company-history',
    component: () => import('@/views/Company/CompanyHistory.vue'),
    meta: {
      title: 'navigationDrawer.companyHistory',
      access: 'private',
      permission: 'viewCompanyHistory'
    }
  },
]
