import api from '@/plugins/api'
import { course } from '@/configs/endpoints'
import { getQueryString } from '@/configs/functions'

export default {
  state: () => ({
    isLoad: false,
    total_pages: 0,
    count: 0,
    filter: {
      page: 1,
      page_size: 10,
      ordering: '',
      only_recent: true
    },
    statisticsList: []
  }),
  mutations: {
    SET_COURSE_STATISTIC (state, data) {
      state[Object.entries(data)[0][0]] = Object.entries(data)[0][1]
    }
  },
  actions: {
    async getCourseStatistics ({ commit, state }, courseID) {
      commit('SET_COURSE_STATISTIC', { isLoad: true })
      const response = await api.get(course.courseStatistic(courseID) + getQueryString(state.filter))
      commit('SET_COURSE_STATISTIC', { isLoad: false })
      if (response?.code === 200) {
        commit('SET_COURSE_STATISTIC', { total_pages: response.data.total_pages })
        commit('SET_COURSE_STATISTIC', { count: response.data.count })
        commit('SET_COURSE_STATISTIC', { statisticsList: response.data.results })
      }
      return response
    }
  }
}
