export default [
  {
    path: '/group',
    name: 'groups',
    component: () => import('@/views/Group/List.vue'),
    meta: {
      title: 'navigationDrawer.groups',
      access: 'private',
    },
  },
  {
    path: '/group/create',
    name: 'group-create',
    component: () => import('@/views/Group/Form.vue'),
    meta: {
      title: 'navigationDrawer.groups',
      access: 'private',
      permission: 'editGroup',
    },
  },
  {
    path: '/group/:id',
    name: 'group-info',
    component: () => import('@/views/Group/Info.vue'),
    meta: {
      title: 'navigationDrawer.groups',
      access: 'private',
    },
  },
  {
    path: '/group/:id/edit',
    name: 'group-edit',
    component: () => import('@/views/Group/Form.vue'),
    meta: {
      title: 'navigationDrawer.groups',
      access: 'private',
      permission: 'editGroup',
    },
  },
]
