import Vue from 'vue'
import api from '@/plugins/api'
import i18n from '@/plugins/vue-i18n'
import { auth, user, app } from '@/configs/endpoints'
import { changeToken, downloadBlob, getQueryString } from '@/configs/functions'
import { logout } from '@ananasbear/auth'
import router from '@/router'

export default {
  state: () => ({
    spacePositions: [],
    spaceSubdivisions: [],
    users: [],
    profile: null,
    memberProfile: null,
    total_pages: null,
    count: null,
    isLoad: false,
    usersListFile: null,
    sleekplanToken: '',
    filter: {
      page: 1,
      page_size: 16,
      ordering: 'user_full_info',
      user_type: ''
    },
    spacesUsersFilter: {
      page: 1,
      page_size: 16
    },
    allSpacesUsersTotalPages: null,
    allSpacesUsersCount: null,
    allSpacesUsers: []
  }),
  mutations: {
    SET_DATA_USER (state, data) {
      state[Object.entries(data)[0][0]] = Object.entries(data)[0][1]
    },
    DELETE_DATA_USER (state, data) {
      const param = Object.entries(data)[0]
      state[param[0]] = state[param[0]].filter(item => item.id !== param[1])
    },
    UPDATE_DATA_USER (state, data) {
      const index = state[Object.entries(data)[0][0]].findIndex(item => item.id === Object.entries(data)[0][1].id)
      Vue.set(state[Object.entries(data)[0][0]], index, Object.entries(data)[0][1])
    },
    ADD_DATA_USER (state, data) {
      state[Object.entries(data)[0][0]].push(Object.entries(data)[0][1])
    },
  },
  actions: {
    async sendLogoutAction ({ commit }) {
      const response = await api.post(app.logoutAction)
      if (response?.code === 200) {
        logout()
        if (window.$sleek) {
          window.$sleek.resetUser()
          document.getElementById('sleek-button')?.remove()
        }
        await router.push('/login')
        commit('SET_DATA_USER', { profile: null })
      }
      return response
    },
    async getAllUsersFromAllSpaces ({ commit, state }, params) {
      commit('SET_DATA_USER', { isLoad: true })
      const queryString = getQueryString(params || state.spacesUsersFilter)
      const response = await api.get(`${ user.getAllSpacesUsers }${ queryString }`)
      if (response?.code === 200) {
        commit('SET_DATA_USER', { allSpacesUsersTotalPages: response.data.total_pages })
        commit('SET_DATA_USER', { allSpacesUsersCount: response.data.count })
        commit('SET_DATA_USER', { allSpacesUsers: response.data.results })
      }
      commit('SET_DATA_USER', { isLoad: false })
      return response
    },
    async setAuth (context, body) {
      return await api.post(auth.general.login, { body })
    },
    getUserInfoFromGoogle (context, params) {
      return api.get(`${ user.getInfoWithGoogle }${ getQueryString(params) }`).then(response => {
        if (response?.code === 200) return response.data
      })
    },
    getUserInfoFromFacebook (context, params) {
      return api.get(`${ user.getInfoWithFacebook }${ getQueryString(params) }&${ `fields=first_name,last_name,email` }`)
        .then(response => {
          if (response?.code === 200) return response.data
      })
    },
    async createdUser ({ commit }, body) {
      const response = await api.post(user.user, { body })
      if (response?.code === 201) commit('ADD_DATA_USER', { users: response.data })
      return response
    },
    async getProfile ({ commit }) {
      const response = await api.get(user.getProfile)
      if (response?.code === 200) {
        localStorage.setItem('LOCALE', response.data.language || 'uk')
        i18n.locale = response.data.language || 'uk'
        commit('SET_DATA_USER', { profile: response.data })
      }
      return response
    },
    async getUsers ({ commit, state }, params) {
      commit('SET_DATA_USER', { isLoad: true })
      const queryString =  getQueryString(params || state.filter)
      const response = await api.get(`${ user.user }${ queryString }`)
      if (response?.code === 200) {
        commit('SET_DATA_USER', { total_pages: response.data.total_pages })
        commit('SET_DATA_USER', { count: response.data.count })
        commit('SET_DATA_USER', { users: response.data.results })
      }
      commit('SET_DATA_USER', { isLoad: false })
      return response
    },
    getPositions (context) {
      return api.get(user.getSpacePositions).then(response => {
        if (response?.code === 200) context.commit('SET_DATA_USER', { spacePositions: response.data })
        return response
      })
    },
    getSubdivisions (context) {
      return api.get(user.getSpaceSubdivisions).then(response => {
        if (response?.code === 200) context.commit('SET_DATA_USER', { spaceSubdivisions: response.data })
        return response
      })
    },
    async getInfo (context, id) {
      const response = await api.get(user.userAction(id))
      if (response?.code === 200) context.commit('ADD_DATA_USER', { users: response.data })
      return response
    },
    async editInfo (context, params) {
      const response = await api.patch(user.userAction(params.id), { body: params.body })
      if (response?.code === 200) context.commit('UPDATE_DATA_USER', { users: response.data })
      return response
    },
    async createSecondUserAccount (context, body) {
      return await api.post(user.memberships(), { body })
    },
    async getMembership({ commit }, params) {
      const queryString = getQueryString(params)
      try {
        const response = await api.get(`${ user.memberships() }${ queryString }`)
        commit('SET_DATA_USER', { memberProfile: response.code === 200 ? response.data[0] : null })
        return response
      } catch (exception) {
        console.error('GET_MEMBERSHIP_ERROR: ', exception)
      }
    },
    async deleteUser(context, userId) {
      const response = await api.delete(user.memberships(userId))
      if (response?.code === 204) context.commit('DELETE_DATA_USER', { users: userId })
      return response
    },
    async setAvatar (context, params) {
      return await api.post(user.uploadUserPhoto(params.id), { body: params.body })
    },
    async blockUserInCompany (context, params) {
      const response = await api.post(user.blockUserInCompany, { body: params })
      if (response?.code === 200) {
        const response = await api.get(user.userAction(params.user_pk))
        if (response?.code === 200) context.commit('UPDATE_DATA_USER', { users: response.data })
      }
    },
    async deleteAvatar (context, id) {
      return await api.delete(user.removeUserPhoto(id))
    },
    async convertToken (context, body) {
      return await api.post(user.convertToken, { body })
    },
    async rememberUserPass (context, body) {
      const response = await api.post(user.passwordReset, { body })
      if (response?.code === 201) context.commit('UPDATE_DATA_USER')
      return response
    },
    async setNewPassword (context, body) {
      return await api.post(user.setNewPassword, { body })
    },
    async finishRegistration (context, body) {
      return await api.post(user.finishRegistration, { body })
    },
    async getUsersListFile (context, action) {
      const response = await api.get(user.actionUserListFile(action), { responseType: 'blob' })
      if (response?.code === 200) downloadBlob(response.data, 'users')
    },
    async uploadUsersListFile (context, params) {
      return await api.post(user.actionUserListFile('import'), { body: params.body })
    },
    async getUsersListTemplate ({ commit }) {
      commit('SET_DATA_USER', { isLoad: true })
      const response = await api.get(user.getUsersListTemplate, { responseType: 'blob' })
      if (response?.code === 200) {
        downloadBlob(response.data, 'users_template.xlsx')
        commit('SET_DATA_USER', { isLoad: false })
      }
    },
    async changeUserRole ({ commit }, body) {
      commit('SET_DATA_USER', { isLoad: true })
      try {
        const response = await api.post(user.changeCurrenRole, { body })
        response?.data?.access && changeToken(response?.data.access)
      } catch (exception) {
        console.error('CHANGE_USER_ROLE_ERROR: ', exception)
      } finally {
        commit('SET_DATA_USER', { isLoad: false })
      }
    },
    async getSleekplanToken ({ commit }) {
      const response = await api.post(user.sleekplanToken)
      if (response?.code === 200) commit('SET_DATA_USER', { sleekplanToken: response.data.sleekplan_token })
      return response
    },
    async changeUserPassword ({ commit }, body) {
      commit('SET_DATA_USER', { isLoad: true })
      const response = await api.post(user.changePassword, { body })
      commit('SET_DATA_USER', { isLoad: false })
      return response
    }
  },
  getters: {
    userById: state => id => state.users.find(user => user.id === id),
    activeSpaceId: state => state.profile?.current_login_info?.space_pk
  }
}
