import api from '@/plugins/api'
import Vue from 'vue'
import { program, app } from '@/configs/endpoints'
import { getQueryString } from '@/configs/functions'

export default {
  state: () => ({
    availableProgramTagsTotalPages: null,
    availableProgramTags: [],
    programTagsFilter: {
      page: 1,
      page_size: 10
    },
    isLoad: false,
    tab: 0,
    programs: [],
    programsCountPages: null,
    programsCountTotal: null,
    selectedProgram: null,
    filter: {
      page: 1,
      page_size: 10
    }
  }),
  mutations: {
    SET_DATA_PROGRAMS (state, data) {
      state[Object.entries(data)[0][0]] = Object.entries(data)[0][1]
    },
    DELETE_DATA_PROGRAMS (state, data) {
      const param = Object.entries(data)[0]
      state[param[0]] = state[param[0]].filter(item => item.id !== param[1])
    },
    UPDATE_DATA_PROGRAMS (state, data) {
      const index = state[Object.entries(data)[0][0]].findIndex(item => item.id === Object.entries(data)[0][1].id)
      Vue.set(state[Object.entries(data)[0][0]], index, Object.entries(data)[0][1])
    },
    ADD_DATA_PROGRAMS (state, data) {
      state[Object.entries(data)[0][0]].push(Object.entries(data)[0][1])
    },
  },
  actions: {
    async getProgramsTags ({ commit, state }, params) {
      commit('SET_DATA_PROGRAMS', { isLoad: true })
      const response = await api.get(`${ app.programTags }${ getQueryString(params || state.programTagsFilter) }`)
      commit('SET_DATA_PROGRAMS', { isLoad: false })
      if (response?.code === 200) {
        commit('SET_DATA_PROGRAMS', { availableProgramTags: response.data.results })
        commit('SET_DATA_PROGRAMS', { availableProgramTagsTotalPages: response.data.total_pages }) 
      }
      return response
    },
    async getEduPrograms({ state, commit }, params) {
      commit('SET_DATA_PROGRAMS', { isLoad: true })
      const response = await api.get(program.programs() + getQueryString(params || state.filter))
      commit('SET_DATA_PROGRAMS', { isLoad: false })
      if (response?.code === 200) {
        commit('SET_DATA_PROGRAMS', { programs: response.data.results })
        commit('SET_DATA_PROGRAMS', { programsCountPages: response.data.total_pages })
        commit('SET_DATA_PROGRAMS', { programsCountTotal: response.data.count })
      }
      return response
    },
    async deleteProgram(context, id) {
      const response = await api.delete(program.programs(id), { responseType: 'text' })
      if (response?.code === 204) context.commit('DELETE_DATA_PROGRAMS', { programs: id })
      return response
    },
    async getInfoProgram({ commit }, id) {
      const response = await api.get(program.programs(id))
      if (response?.code === 200) commit('SET_DATA_PROGRAMS', { selectedProgram: response.data })
      return response
    },
    async uploadProgramPhoto (context, params) {
      return await api.post(program.uploadPhoto(params.programId), { body: params.body })
    },
    async removeProgramPhoto (context, programID) {
      return await api.delete(program.removePhoto(programID))
    },
    async createProgram ({ commit }, params) {
      commit('SET_DATA_PROGRAMS', { isLoad: true })
      const response = await api.post(`${ program.programs() }`, { body: params.body })
      commit('SET_DATA_PROGRAMS', { isLoad: false })
      if (response?.code === 201 && response.data) commit('SET_DATA_PROGRAMS', { selectedProgram: response.data })
      return response
    },
    async editProgram ({ commit }, params) {
      commit('SET_DATA_PROGRAMS', { isLoad: true })
      const response = await api.patch(program.programs(params.programId), { body: params.body })
      commit('SET_DATA_PROGRAMS', { isLoad: false })
      if (response?.code === 200 && response.data) commit('SET_DATA_PROGRAMS', { selectedProgram: response.data })
      return response
    }
  },
  getters: {
    programByID: state => id => state.programs.find(program => program.id === id)
  }
}
