import api from '@/plugins/api'
import { course, app } from '@/configs/endpoints'
import { getQueryString } from '@/configs/functions'
import Vue from 'vue'

export default {
  state: () => ({
    coursesStatus: [],
    isExaminationInProcess: false,
    availableCourseTags: [],
    availableCourseTagsTotalPages: null,
    coursesName: [],
    courseExams: [],
    courseTests: [],
    dynamicStatistic: {},
    dynamicStatisticFilter: {
      only_recent: true
    },
    appointedFormsCount: 0,
    allAppointedForms: [],
    blockedUsers: [],
    isLoad: false,
    appointedForms: [],
    coursesList: [],
    selectedCourse: null,
    total_pages: 0,
    count: 0,
    filter: {
      page: 1,
      page_size: 10
    },
    tab: 0,
    image: null,
    courseTagsFilter: {
      page: 1,
      page_size: 10
    },
    appointedFormsFilter: {
      page: 1,
      user: '',
      course_name: '',
      form_type: '',
      passed_form_status: '',
      exam_time_end: '',
      page_size: 10,
      ordering: 'form_name'
    },
    courseFormsList: [],
    courseForm: {},
    selectedForm: {},
    initSelectedForm: {
      name: '',
      description: '',
      form_type: null,
      passing_score_type: null,
      passing_score: null,
      time_limit: null,
      text_after_success_passing: '',
      text_after_failure_passing: '',
      is_one_attempt_allowed: true,
      shuffle_questions: false,
      disable_autosave_for_all_respondents: false,
      publication_after_passing: false,
      show_right_answers: false,
      issue_certificate: false,
      shuffle_answers: false,
      questions: [{
        title: '',
        description: '',
        multiple_right_answers: false,
        files: [],
        score: 1,
        answers: [{ title: '', is_right_answer: false }]
      }]
    },
    constructorValidationErrors: [],
    passedFormsList: [],
    selectedPassedForm: {},
    // Education materials below
    educationMaterials: [],
    selectedEducationMaterial: null,
    temporaryUploadedFiles: []
  }),
  mutations: {
    SET_DATA_COURSE (state, data) {
      state[Object.entries(data)[0][0]] = Object.entries(data)[0][1]
    },
    ADD_DATA_COURSE (state, data) {
      state[Object.entries(data)[0][0]].push(Object.entries(data)[0][1])
    },
    UPDATE_DATA_COURSE (state, data) {
      const index = state[Object.entries(data)[0][0]].findIndex(item => item.id === Object.entries(data)[0][1].id)
      Vue.set(state[Object.entries(data)[0][0]], index, Object.entries(data)[0][1])
    },
    DELETE_DATA_COURSE (state, data) {
      state[Object.entries(data)[0][0]] = state[Object.entries(data)[0][0]].filter(item => {
          return item.id !== Object.entries(data)[0][1]
      })
    },
    DELETE_DATA_COURSE_BY_INDEX (state, data) {
      state[Object.entries(data)[0][0]].splice(Object.entries(data)[0][1], 1)
    },
    DELETE_DATA_COURSE_BY_VALUE (state, data) {
      state[Object.entries(data)[0][0]] = state[Object.entries(data)[0][0]].filter(item => {
          return item !== Object.entries(data)[0][1]
      })
    }
  },
	actions: {
    async getCourseStatus({ commit }) {
      commit('SET_DATA_COURSE', { isLoad: true })
      const response = await api.get(course.coursesStatus)
      commit('SET_DATA_COURSE', { isLoad: false })
      if (response?.code === 200) commit('SET_DATA_COURSE', { coursesStatus: response.data })
    },
    async getCourseTags ({ commit, state }, params) {
      commit('SET_DATA_COURSE', { isLoad: true })
      const response = await api.get(`${ app.courseTags }${ getQueryString(params || state.courseTagsFilter) }`)
      commit('SET_DATA_COURSE', { isLoad: false })
      if (response?.code === 200) {
        commit('SET_DATA_COURSE', { availableCourseTags: response.data.results })
        commit('SET_DATA_COURSE', { availableCourseTagsTotalPages: response.data.total_pages })
      }
      return response
    },
    getBlockedUser ({ commit }, params) {
      return api.get(`${ course.blockedUserFromCourse(params.courseId) }${ getQueryString(params.user) }`)
        .then(response => {
          if (response?.code === 200) commit('SET_DATA_COURSE', { blockedUsers: response.data })
          return response
      })
    },
    async unblockFromCourse (context, params) {
      const response = await api.delete(
        course.blockedUserFromCourse(params.courseId, params.objectId),
        { responseType: 'text' })
      if (response?.code === 204) await context.dispatch('getCourse', params.courseId)
    },
    async blockFromCourse (context, params) {
      const response = await api.post(course.blockedUserFromCourse(params.course),  { body: params })
      if (response?.code === 201) await context.dispatch('getCourse', params.course)
    },
    async uploadCoursePhoto (context, params) {
        return await api.post(course.uploadPhoto(params.courseId), { body: params.body })
    },
    async removeCoursePhoto (context, id) {
        return await api.delete(course.deletePhoto(id))
    },
    async updateQuestionsList (context, body) {
      return await api.post(course.updateQuestionsList, { body })
    },
    async removeQuestion (context, id) {
      return await api.delete(course.question(id))
    },
    async uploadQuestionFiles (context, params) {
      return await api.post(course.questionUploadFiles(params.questionId), { body: params.body })
    },
    async removeQuestionFile (context, params) {
      return await api.delete(course.questionRemoveFile(params.questionId, params.fileId))
    },
    async getCourse ({ commit }, courseId) {
      commit('SET_DATA_COURSE', { isLoad: true })
      const response = await api.get(course.course(courseId))
      commit('SET_DATA_COURSE', { isLoad: false })
      if (response?.code === 200) commit('SET_DATA_COURSE', { selectedCourse: response.data })
      return response
    },
    async getAllAppointedForms ({ commit }, params) {
      const response = await api.get(`${ course.appointedForm }${ getQueryString(params) }`)
      if (response?.code === 200) commit('SET_DATA_COURSE', { allAppointedForms: response.data.results })
      return response
    },
    async getCoursesName ({ commit }, params) {
      const response = await api.get(`${ course.getCoursesName }${ getQueryString(params) }`)
      if (response?.code === 200) commit('SET_DATA_COURSE', { coursesName: response.data })
      return response
    },
    async getAppointedForms ({ commit, state }, params) {
      commit('SET_DATA_COURSE', { isLoad: true })
      const response = await api.get(`${ course.appointedForm }${ getQueryString(params || state.appointedFormsFilter) }`)
      commit('SET_DATA_COURSE', { isLoad: false })
      if (response?.code === 200) {
        commit('SET_DATA_COURSE', { appointedForms: response.data.results })
        commit('SET_DATA_COURSE', { appointedFormsCount: response.data.count })
        commit('SET_DATA_COURSE', { total_pages: response.data.total_pages })
      }
      return response
    },
    async getCourses ({ commit, state }, params) {
      commit('SET_DATA_COURSE', { isLoad: true })
      const response = await api.get(`${ course.course() }${ getQueryString(params || state.filter) }`)
      commit('SET_DATA_COURSE', { isLoad: false })
      if (response?.code === 200) {
        commit('SET_DATA_COURSE', { coursesList: response.data.results })
        commit('SET_DATA_COURSE', { filter: state.filter, page: response.data.current })
        commit('SET_DATA_COURSE', { count: response.data.count })
        commit('SET_DATA_COURSE', { total_pages: response.data.total_pages })
      }
      return response
    },
    async createCourse ({ commit }, body) {
      commit('SET_DATA_COURSE', { isLoad: true })
      const response = await api.post(course.course(), { body })
      commit('SET_DATA_COURSE', { isLoad: false })
      if (response?.code === 201) commit('SET_DATA_COURSE', { selectedCourse: response.data })
      return response
    },
    editSequenceCourseMaterials (context, data) {
      return api.post(course.updateMaterialsSequence(data.courseId), { body: data.body }).then(response => {
        return response
      })
    },
    async editCourse ({ commit }, params) {
      commit('SET_DATA_COURSE', { isLoad: true })
      try {
        const response = await api.patch(`${ course.course(params.courseId) }`, { body: params.body })
        if (response?.code === 200) commit('SET_DATA_COURSE', { selectedCourse: response.data })
        return response
      } catch (exception) {
        console.error('GET_COURSES_ERROR: ', exception)
      } finally {
        commit('SET_DATA_COURSE', { isLoad: false })
      }
    },
    async deleteCourse (context, courseID) {
      return await api.delete(course.course(courseID))
    },
    async leaveCourse (context, courseID) {
      return await api.post(course.leaveCourse(courseID))
    },
    async cloneCourse (context, params) {
      return await api.post(course.cloneCourseEducationMaterial(params.courseId, params.materialId), { body: params.body } )
    },
    downloadCourseCertificate (context, params) {
      return api.post(course.downloadCourseCertificate(params.courseId), { responseType: 'blob', body: params.body })
        .then(response => { return response })
    },
    getEducationMaterials ({ commit }, filter) {
      return api.get(`${ course.getCourseEducationMaterils }${ getQueryString(filter) }`).then(response => {
        if (response?.code === 200) {
          if (filter.form_type === 1) commit('SET_DATA_COURSE', { courseTests: response.data })
          else commit('SET_DATA_COURSE', { courseExams: response.data })
        }
      })
    },
    getCourseForm ({ commit }, params) {
      return api.get(course.courseForm(params.courseId, params.formId)).then(response => {
        if (response?.code === 200) commit('SET_DATA_COURSE', { selectedForm: response.data })
        return response
      })
    },
    async getCourseDynamicStatistic ({ commit, state }, courseID) {
      const response = await api.get(course.dynamicStatistic(courseID) + getQueryString(state.dynamicStatisticFilter))
      if (response?.code === 200) commit('SET_DATA_COURSE', { dynamicStatistic: response.data })
    },
    async getCourseFormData (context, params) {
      return await api.get(course.courseForm(params.courseId, params.formId))
    },
    createCourseForm (context, params) {
      context.commit('SET_DATA_COURSE', { isLoad: true })
      return api.post(course.courseForm(params.courseId), { body: params.body }).then(response => {
        context.commit('SET_DATA_COURSE', { isLoad: false })
        return response
      })
    },
    editCourseForm (context, data) {
      context.commit('SET_DATA_COURSE', { isLoad: true })
      return api.patch(course.courseForm(data.courseId, data.formId), { body: data.body }).then(response => {
        context.commit('SET_DATA_COURSE', { isLoad: false })
        return response
      })
    },
    async getPassedForms ({ commit }, filter) {
      commit('SET_DATA_COURSE', { isLoad: true })
      try {
        const response = await api.get(`${ course.passedForm() }${ getQueryString(filter) }`)
        if (response?.code === 200) commit('SET_DATA_COURSE', { passedFormsList: response.data })
        return response
      } catch (exception) {
        console.error('GET_PASSED_FORMS_ERROR: ', exception)
      } finally {
        commit('SET_DATA_COURSE', { isLoad: false })
      }
    },
    getPassedForm ({ commit }, id) {
      return api.get(course.passedForm(id)).then(response => {
        if (response?.code === 200) commit('SET_DATA_COURSE', { selectedPassedForm: response.data })
        return response
      })
    },
    retakePassedForm ({ dispatch }, data) {
      return api.post(course.retakeForm(data.id), { body: data.body }).then(response => {
        if ([200, 201].includes(response?.code)) dispatch('getCourseStatistics', data.id)
        return response
      })
    },

    // EDUCATION MATERIALS
    getCourseEducationMaterials ({ commit }, courseID) {
      commit('SET_DATA_COURSE', { isLoad: true })
      return api.get(course.educationMaterial(courseID)).then(response => {
        if (response?.code === 200) commit('SET_DATA_COURSE', { educationMaterials: response.data })
        commit('SET_DATA_COURSE', { isLoad: false })
        return response
      })
    },
    getEducationMaterialsForDuplicate (context, data) {
      return api.get(course.educationMaterial(data.courseID) + getQueryString(data.params)).then(response => {
        return response
      })
    },
    setCourseEducationMaterials ({ state, commit }, data) {
      commit('SET_DATA_COURSE', { isLoad: true })
      return api.post(course.educationMaterial(data.courseID), { body: data.body }).then(response => {
        if ([200, 201].includes(response?.code)) {
          commit('DELETE_DATA_COURSE_BY_INDEX', { educationMaterials: state.educationMaterials.length - 1 })
          commit('ADD_DATA_COURSE', { educationMaterials: response.data })
        }
        commit('SET_DATA_COURSE', { isLoad: false })
        return response
      })
    },
    updateCourseEducationMaterials ({ commit }, data) {
      commit('SET_DATA_COURSE', { isLoad: true })
      return api.patch(course.educationMaterial(data.courseID, data.materialID), { body: data.body }).then(response => {
        if (response?.code === 200) commit('UPDATE_DATA_COURSE', { educationMaterials: response.data })
        commit('SET_DATA_COURSE', { isLoad: false })
        return response
      })
    },
    uploadCourseEducationMaterialFile ({ commit }, data) {
      commit('SET_DATA_COURSE', { isLoad: true })
      return api.post(
        course.educationMaterialUploadFile(data.courseID, data.materialID) + getQueryString(data.params), {
          body: data.file,
          requestType: 'binary'
        }).then(response => {
          commit('SET_DATA_COURSE', { isLoad: false })
          return response
      })
    },
    removeCourseEducationMaterialFile ({ commit }, data) {
      commit('SET_DATA_COURSE', { isLoad: true })
      return api.delete(course.educationMaterialDeleteFile(data.courseID, data.materialID, data.fileID), { body: data.body })
        .then(response => {
          commit('SET_DATA_COURSE', { isLoad: false })
          return response
        })
    },
    async getCourseEducationMaterial (context, data) {
      return await api.get(course.educationMaterial(data.courseID, data.materialID))
    },
    removeCourseEducationMaterial ({ commit }, data) {
      return api.delete(course.educationMaterial(data.courseID, data.materialID)).then(response => {
        if (response?.code === 204) commit('DELETE_DATA_COURSE', { educationMaterials: data.materialID })
        return response
      })
    },
    async startFormPassing (context, body) {
      context.commit('SET_DATA_COURSE', { isExaminationInProcess: true })
      return await api.post(course.startFormPassing, { body })
    },
    async sendFormPassingAnswers (context, body) {
      return await api.post(course.sendFormPassingAnswers, { body })
    },
    async unblockCourseEducationMaterial (context, body) {
      return await api.post(course.unblockEducationMaterial, { body })
    }
  },
  getters: {
    courseById: state => id => state.coursesList.find(course => course.id === id)
  }
}
