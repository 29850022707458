<template lang="pug">
v-app
  div(v-if="isLoadedApp").h-100
    div(v-if="$auth.isAuth() && $route.meta.access !== 'public'").h-100.d-flex.bg-blue
      Navigation
      v-main.start-main
        router-view(:key="$route.path")
        SleekplanWidget
    div(v-else).d-flex.justify-center.align-center.start-public.start-img.transition
      router-view
  div(v-else).start-loader
    div.start-img
      Loader
</template>

<script>
import { mapActions, mapState } from 'vuex'
import sleekSetup from '@/plugins/sleek.js'

export default {
  name: 'App',
  components: {
    SleekplanWidget: () => import('@/components/modules/SleekplanWidget.vue'),
    Navigation: () => import('@/components/Navigation.vue'),
    Loader: () => import('@/views/Loader.vue')
  },
  computed: {
    ...mapState({
      profile: state => state.user.profile,
      isLoadedApp: state => state.app.isLoadedApp,
      sleekplanToken: state => state.user.sleekplanToken
    })
  },
  watch: {
    '$route.meta.title': {
      handler (val, oldVal) {
        if (val !== oldVal) this.setPageTitle()
      },
      immediate: true
    },
    profile () {
      this.setSleekPlan()
    }
  },
  methods: {
    ...mapActions(['getSleekplanToken']),

    async setSleekPlan () {
      if (this.$auth.isAuth() && !window.$sleek) {
        if (!this.sleekplanToken) await this.getSleekplanToken()
        const productID = this.profile.language === 'uk'
          ? process.env.VUE_APP_SLEEK_UA_PRODUCT_ID
          : process.env.VUE_APP_SLEEK_EN_PRODUCT_ID
        sleekSetup({ userToken: this.sleekplanToken, productID })
      }
    },

    setPageTitle () {
      if (this.$route.meta.title) document.title = `${ this.$t(this.$route.meta.title) } | Edhelp`
      else document.title = 'Edhelp'
    }
  }
}
</script>
