<template lang="pug">
v-dialog(v-model="isFullSizeImage" :max-width="1000" :overlay-opacity="0.3" @click:outside="isFullSizeImage = false")
  v-card.pa-5
    div.text-right
      v-icon(@click="isFullSizeImage = false").mb-2 mdi-close
    img(:src="image" width="100%" height="100%" alt="full-size-image").rounded-lg
</template>

<script>
  export default {
    data () {
      return {
        image: '',
        isFullSizeImage: false
      }
  },
    methods: {
      changeModalState (imageUrl) {
        this.image = imageUrl
        this.isFullSizeImage = !this.isFullSizeImage
      }
    }
  }
</script>