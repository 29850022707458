export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/User/UserInfo.vue'),
    meta: {
      title: 'navigationDrawer.profile',
      access: 'private'
    }
  },
  {
    path: '/profile/edit',
    name: 'profile-edit',
    component: () => import('@/views/User/Form.vue'),
    meta: {
      title: 'navigationDrawer.profile',
      access: 'private'
    }
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/User/User'),
    meta: {
      title: 'navigationDrawer.users',
      access: 'private',
      permission: 'viewUser'
    }
  },
  {
    path: '/user/create',
    name: 'user-create',
    component: () => import('@/views/User/Form.vue'),
    meta: {
      title: 'navigationDrawer.users',
      access: 'private',
      permission: 'createUser'
    }
  },
  {
    path: '/user/import',
    name: 'user-import',
    component: () => import('@/views/User/Import/UsersImport'),
    meta: {
      title: 'navigationDrawer.users',
      access: 'private',
      permission: 'importUser'
    }
  },
  {
    path: '/user/:id',
    name: 'user-info',
    component: () => import('@/views/User/UserInfo.vue'),
    meta: {
      title: 'navigationDrawer.users',
      access: 'private',
      permission: 'viewUser'
    }
  },
  {
    path: '/user/:id/edit',
    name: 'user-edit',
    component: () => import('@/views/User/Form.vue'),
    meta: {
      title: 'navigationDrawer.users',
      access: 'private',
      permission: 'editUser'
    }
  }
]
