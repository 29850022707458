import Vue from 'vue'
import VueI18n from 'vue-i18n'
import uk from '@/configs/translations/uk.json'
import en from '@/configs/translations/en-US.json'
import de from '@/configs/translations/de.json'

Vue.use(VueI18n)

const messages = { uk, en, de }

export default new VueI18n({
  locale: localStorage.getItem('LOCALE') || 'uk',
  fallbackLocale: 'uk',
  messages
})
