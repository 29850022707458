import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

const options = {
  breakpoint: {
    scrollBarWidth: 16,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920,
    },
  },
  icons: {
    iconfont: 'mdi',
    values: {},
  },
  rtl: false,
  theme: {
    dark: false,
    default: 'light',
    disable: false,
    themes: {
      light: {
        grey: '#9E9E9E',
        grey_50: '#F9FAFB',
        grey_100: '#F2F4F7',
        grey_200: '#E4E7EC',
        blue_100: '#EAECF5',
        grey_300: '#D0D5DD',
        grey_400: '#98A2B3',
        grey_500: '#667085',
        grey_600: '#475467',
        grey_700: '#344054',
        grey_900: '#101828',
        blue_grey_25: '#FCFCFD',
        blue_grey_300: '#9EA5D1',
        blue_200: '#4e5ba7',
        blue_grey_700: '#363F72',
        blue_50: '#F8F9FC',
        blue_600: '#3E4784',
        blue_light_50: '#F0F9FF',
        blue_light_700: '#026AA2',
        success: '#28C76F',
        success_50: '#ECFDF3',
        success_100: '#D1FADF',
        success_500: '#12B76A',
        success_600: '#039855',
        success_700: '#027A48',
        error: '#EA5455',
        error_50: '#FEF3F2',
        error_70: '#B54708',
        error_500: '#F04438',
        error_600: '#D92D20',
        error_700: '#B42318',
        indigo_100: '#E0EAFF',
        white: '#FFFFFF',
        violet: '#673AB7',
        yellow: '#FF9800',
        yellow_50: '#FFFAEB',
        yellow_400: '#FDB022',
        green: '#009688',
        black: '#000000',
        info: '#0BA5EC',
        warning: '#FD853A',
      },
      dark: {
        grey: '#9E9E9E',
        grey_50: '#F9FAFB',
        grey_100: '#F2F4F7',
        grey_200: '#E4E7EC',
        blue_100: '#EAECF5',
        grey_300: '#D0D5DD',
        grey_400: '#98A2B3',
        grey_500: '#667085',
        grey_600: '#475467',
        grey_700: '#344054',
        grey_900: '#101828',
        blue_grey_25: '#FCFCFD',
        blue_grey_300: '#9EA5D1',
        blue_200: '#4e5ba7',
        blue_grey_700: '#363F72',
        blue_50: '#F8F9FC',
        blue_600: '#3E4784',
        blue_light_50: '#F0F9FF',
        blue_light_700: '#026AA2',
        success: '#28C76F',
        success_50: '#ECFDF3',
        success_100: '#D1FADF',
        success_600: '#039855',
        success_700: '#027A48',
        error: '#EA5455',
        error_50: '#FEF3F2',
        error_500: '#F04438',
        error_600: '#D92D20',
        error_700: '#B42318',
        indigo_100: '#E0EAFF',
        white: '#FFFFFF',
        violet: '#673AB7',
        yellow: '#FF9800',
        green: '#009688',
        black: '#000000',
        info: '#0BA5EC',
        warning: '#FD853A',
      },
    },
  },
}

export default new Vuetify({
  ...options,
})
