import 'izitoast/dist/css/iziToast.min.css'
import i18n from '@/plugins/vue-i18n.js'

export default {
  global: {
    close: true,
    pauseOnHover: true,
    timeout: 3000,
    progressBar: true,
    layout: 2,
    position: 'topRight'
  },

  info: {
    title: i18n.t('notify.info')
  },

  success: {
    iconUrl: require('@/assets/images/icons/notify/success.svg'),
    title: i18n.t('notify.success')
  },

  warning: {
    iconUrl: require('@/assets/images/icons/notify/warning.svg'),
    title: i18n.t('notify.warning')
  },

  error: {
    iconUrl: require('@/assets/images/icons/notify/error.svg'),
    title: i18n.t('notify.error')
  }
}
