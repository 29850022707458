export default {
  state: () => ({
    ectsScoreSystem: [
      { id: 1, score_in_points: '90-100', score_in_ects: 'A', score_explanation: 'ects.scoreExplanation.a' },
      { id: 2, score_in_points: '82-89', score_in_ects: 'B', score_explanation: 'ects.scoreExplanation.b' },
      { id: 3, score_in_points: '75-81', score_in_ects: 'C', score_explanation: 'ects.scoreExplanation.c' },
      { id: 4, score_in_points: '67-74', score_in_ects: 'D', score_explanation: 'ects.scoreExplanation.d' },
      { id: 5, score_in_points: '60-66', score_in_ects: 'E', score_explanation: 'ects.scoreExplanation.e' },
      { id: 6, score_in_points: '35-59', score_in_ects: 'FX', score_explanation: 'ects.scoreExplanation.fx' },
      { id: 7, score_in_points: '1-34', score_in_ects: 'F', score_explanation: 'ects.scoreExplanation.f' }
    ],
    formTypes: [
      { id: 1, name: 'course.formType.practice' },
      { id: 2, name: 'course.formType.exam' }
    ],
    courseEducationMaterials: ['theory', 'video', 'audio', 'presentation', 'pdf', 'practice', 'exam'],
    materialAccessTerms: [
      { value: 'all_materials_access', name: 'tableHeaders.educationMaterials.acceptAll' },
      { value: 'chain_access', name: 'tableHeaders.educationMaterials.acquaintedMaterial' }
    ],
    passedFormStatuses: [
      { id: 1, name: 'statuses.inProgress' },
      { id: 2, name: 'statuses.completed' },
      { id: 3, name: 'tableHeaders.assigned' }
    ],
    appointedFormStatuses: [
      { id: 1, name: 'tableHeaders.passedTime' },
      { id: 2, name: 'tableHeaders.notPassed' },
      { id: 3, name: 'tableHeaders.completedItems' },
      { id: 4, name: 'tableHeaders.startedItems' }
    ],
    passedSuccessfullyStatuses: [
      { value: 'true', name: 'tableHeaders.passedTime' },
      { value: 'false', name: 'tableHeaders.notPassed' }
    ],
    languagesList: [
      { name: 'Українська', value: 'uk' },
      { name: 'English', value: 'en-US' },
      { name: 'Deutsch', value: 'de' }
    ]
  }),
  mutations: {},
  actions: {},
  getters: {
    directoryByID: state => (directory, id) => state[directory].find(item => item.id === id) || {}
  }
}
