<template lang="pug">
v-tooltip(:disabled="disabled || !text" bottom color="#FFFFFF" max-width="320" content-class="custom-tooltip")
  template(#activator="{ on, attrs }")
    div(v-on="on" v-bind="attrs")
      slot(name="activator")
  div.fs-12.font-weight-500.color-grey__700 {{ text }}
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    text: { type: String, default: '' },
    disabled: { type: Boolean, default: false }
  }
}
</script>

<style lang="sass">
.custom-tooltip
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)
  border-radius: 8px
</style>
