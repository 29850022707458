const sleekSetup = data => {
    window.$sleek = []
    window.SLEEK_USER = { token: data.userToken }
    window.SLEEK_PRODUCT_ID = data.productID
    const s = document.createElement('script')
    s.src = 'https://client.sleekplan.com/sdk/e.js'
    s.async = 1
    document.getElementsByTagName('head')[0].appendChild(s)
}

export default sleekSetup
