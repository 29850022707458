import api from '@/plugins/api'
import { user, app } from '@/configs/endpoints'
import { changeToken, errorsHandler, getQueryString } from '@/configs/functions'

export default {
  state: () => ({
    isLoad: false,
    userCompany: [],
    // totalPages: null,
    selectedCompany: null,
    companyHistory: [],
    companyHistoryTotalPages: null,
    companyHistoryFilter: {
      page: 1,
      page_size: 10,
      action: null,
      author: null,
      created_at__gte: null,
      created_at__lte: null,
      search: null,
      section: null
    },
  }),
  mutations: {
    SET_DATA_COMPANY (state, data) {
      state[Object.entries(data)[0][0]] = Object.entries(data)[0][1]
    },
  },
  actions: {
    getCompanyHistory ({ commit, state }, params) {
      return api.get(`${ app.getCompanyHistory }${ getQueryString(params || state.companyHistoryFilter) }`)
        .then(response => {
          if (response?.code === 200) {
            commit('SET_DATA_COMPANY', { companyHistory: response.data.results })
            commit('SET_DATA_COMPANY', { companyHistoryTotalPages: response.data.total_pages })
          }
        return response
      })
    },
    restoreHistoryObject(context, id) {
      return api.get(app.restoreHistoryObject(id)).then(response => {
        return response
      })
    },
    uploadCompanyPhoto (context, data) {
      return api.post(user.uploadPhotoToCompany(data.companyId), { body: data.body }).then(response => {
        if (response?.code === 400) errorsHandler(response)
        return response
      })
    },
    deleteCompanyPhoto (context, companyId) {
      return api.delete(user.removePhotoToCompany(companyId)).then(response => {
        return response
      })
    },
    createCompany (context, body) {
      return api.post(user.getUserCompany(), { body }).then(response => {
        return response
      })
    },
    async emailConfirmation (context, body) {
      const response = await api.post(user.setEmailConfirmation, { body })
      if (response?.code === 200) changeToken(response.data.access)
      return response
    },
    editCompany ({ commit }, data) {
      return api.patch(user.getUserCompany(data.companyId), { body: data.body }).then(response => {
        if (response?.code === 200) commit('SET_DATA_COMPANY', { selectedCompany: response.data })
        return response
      })
    },
    registrationCompany (context, params) {
      context.commit('SET_DATA_COMPANY', { isLoad: true })
      return api.post(user.registrationCompany, { body: params }).then(response => {
        context.commit('SET_DATA_COMPANY', { isLoad: false })
        return response
      })
    },
    async getCompany ({ commit }) {
      commit('SET_DATA_COMPANY', { isLoad: true })
        const response = await api.get(user.getUserCompany())
        if (response?.code === 200) commit('SET_DATA_COMPANY', { userCompany: response.data })
        commit('SET_DATA_COMPANY', { isLoad: false })
        return response
    },
    async getSelectedCompany ({ commit }, companyId) {
      commit('SET_DATA_COMPANY', { isLoad: true })
      try {
        const response = await api.get(user.getUserCompany(companyId))
        if (response?.code === 200) {
          commit('SET_DATA_COMPANY', {
            selectedCompany: {
              ...response.data,
              company_website: response.data.company_website?.split('://').reverse()[0] || ''
            }
          })
        }
      } catch (exception) {
        console.error('GET_SELECTED_COMPANY_ERROR', exception)
      } finally {
        commit('SET_DATA_COMPANY', { isLoad: false })
      }
    },
    async changeCurrentCompany ({ commit }, body) {
      commit('SET_DATA_COMPANY', { isLoad: true })
      try {
        const response = await api.post(user.changeCurrentCompany, { body })
        response?.data?.access && changeToken(response.data.access)
      } catch (exception) {
        console.error('CHANGE_SELECTED_COMPANY_ERROR', exception)
      } finally {
        commit('SET_DATA_COMPANY', { isLoad: false })
      }
    },
  },
}
