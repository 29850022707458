import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import authorization from '@/plugins/auth'
import { checkAccess, setRoles } from '@/mixins/permissions'

import CompanyRoutes from './company.routes'
import CourseRoutes from './course.routes'
import GroupRoutes from './group.routes'
import ProgramRoutes from './program.routes'
import UserRoutes from './user.routes'
import LibraryRoutes from './library.routes'

Vue.use(Router)

export const routes = [
  ...CompanyRoutes,
  ...CourseRoutes,
  ...GroupRoutes,
  ...ProgramRoutes,
  ...UserRoutes,
  ...LibraryRoutes,
  {
    path: '*',
    component: () => import('@/views/NoAccess'),
    meta: {
      access: 'public'
    }
  },
  {
    path: '/no-access',
    name: 'no-access',
    component: () => import('@/views/NoAccess'),
    meta: {
      access: 'public'
    }
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('@/views/Support'),
    meta: {
      title: 'navigationDrawer.support',
      access: 'private'
    }
  },
  {
    path: '/accounts/password-reset',
    name: 'set-password',
    component: () => import('@/views/Login/SetPassword'),
    meta: {
      title: 'login.setPassword.setNewPassword',
      access: 'public'
    },
  },
  {
    path: '/accounts/finish-registration',
    name: 'finish-registration',
    component: () => import('@/views/Login/FinishRegistration.vue'),
    meta: {
      title: 'login.setPassword.finishRegistration',
      access: 'public'
    }
  },
  {
    path: '/accounts/email-confirmation',
    name: 'email-confirmation',
    component: () => import('@/views/Login/SuccessRegistration'),
    meta: {
      title: 'login.setPassword.SuccessRegistration',
      access: 'public'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/Login'),
    meta: {
      title: 'btn.sign',
      access: 'public'
    }
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    component: () => import('@/views/Login/ForgotPassword.vue'),
    meta: {
      title: 'btn.reSendCredentials',
      access: 'public'
    }
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('@/views/Login/Registration'),
    meta: {
      title: 'create.company',
      access: 'public'
    }
  },
  {
    path: '/invite-registration',
    name: 'invite-registration',
    component: () => import('@/views/Login/Registration'),
    meta: {
      title: 'create.inviteRegistration',
      access: 'public'
    }
  },
  {
    path: '/',
    name: 'main',
    component: () => import('@/views/Main'),
    meta: {
      access: 'private'
    },
    redirect: {
      name: 'profile'
    }
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  if (from.name === to.name && JSON.stringify(from.query) !== JSON.stringify(to.query)) next()
  else {
    if (authorization.isAuth()) {
      if (to.name === 'login') next(to.query?.callback_url || '/profile')
      else {
        store.commit('SET_DATA_APP', { isLoadedApp: false })
        !store.state.user.profile && await store.dispatch('getProfile')
        setRoles()
        if (['no-found', 'server-error'].includes(to.name)) next()
        else if (!to.meta.permission || checkAccess(to.meta.permission)) next()
        else next('/no-access')
        store.commit('SET_DATA_APP', { isLoadedApp: true })
      }
    } else to.meta.access === 'public' ? next() : next('/login')
  }
})

export default router
