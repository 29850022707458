const successStatuses = [1]
const processStatuses = [2]
const warningStatuses = [3]
const errorStatuses = [4]
const secondaryStatuses = [5]

export default id => {
  switch (true) {
    case successStatuses.includes(id):
      return { color: 'success lighten-1', textColor: 'success--text' }
    case processStatuses.includes(id):
      return { color: 'primary lighten-1', textColor: 'primary--text' }
    case warningStatuses.includes(id):
      return { color: 'warning lighten-1', textColor: 'warning--text' }
    case errorStatuses.includes(id):
      return { color: 'error lighten-1', textColor: 'error--text' }
    case secondaryStatuses.includes(id):
      return { color: 'secondary lighten-1', textColor: 'secondary--text' }
    default:
      return { color: 'primary lighten-1', textColor: 'primary--text' }
  }
}

export const usersStatuses = value => {
  switch (true) {
    case value.blocked:
      return {
        name: 'navigationDrawer.blocked',
        color: 'error_50',
        textColor: 'error_700--text',
        badgeColor: 'error_500'
      }
    case !value.email_verified:
      return {
        name: 'general.notActivated',
        color: 'yellow_50',
        textColor: 'error_70--text',
        badgeColor: 'yellow_400'
      }
    case value.is_online:
      return {
        name: 'general.online',
        color: 'success_50',
        textColor: 'success_700--text',
        badgeColor: 'success_500'
      }
    default: return {
      name: 'general.offline',
      color: 'grey_100',
      textColor: 'grey_700--text'
    }
  }
}
