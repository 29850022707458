import Vue from 'vue'
import api from '@/plugins/api'
import { group } from '@/configs/endpoints'
import { getQueryString } from '@/configs/functions'


export default {
  state: () => ({
    isLoad: false,
    groups: [],
    groupInfo: {},
    totalPages: null,
    totalGroups: null,
    groupMembersList: [],
    filter: {
      page: 1,
      page_size: 10
    }
  }),
  mutations: {
    SET_DATA_GROUP (state, data) {
      state[Object.entries(data)[0][0]] = Object.entries(data)[0][1]
    },
    DELETE_DATA_GROUP (state, data) {
      const param = Object.entries(data)[0]
      state[param[0]] = state[param[0]].filter(item => item.id !== param[1])
    },
    UPDATE_DATA_GROUP (state, data) {
      const index = state[Object.entries(data)[0][0]].findIndex(item => item.id === Object.entries(data)[0][1].id)
      Vue.set(state[Object.entries(data)[0][0]], index, Object.entries(data)[0][1])
    },
    ADD_DATA_GROUP (state, data) {
      state[Object.entries(data)[0][0]].push(Object.entries(data)[0][1])
    },
  },
  actions: {
    async getGroups({ commit, state }, params) {
      commit('SET_DATA_GROUP', { isLoad: true })
      const response = await api.get(group.groupAction() + getQueryString(params || state.filter))
      commit('SET_DATA_GROUP', { isLoad: false })
      if (response?.code === 200) {
        commit('SET_DATA_GROUP', { groups: response.data.results })
        commit('SET_DATA_GROUP', { totalPages: response.data.total_pages })
        commit('SET_DATA_GROUP', { totalGroups: response.data.count })
      }
      return response
    },
    async getInfoGroup({ commit }, id) {
      const response = await api.get(group.groupAction(id))
      if (response?.code === 200) commit('SET_DATA_GROUP', { groupInfo: response.data })
      return response
    },
    async createGroup({ commit }, params) {
      const response = await api.post(group.groupAction(), { body: params })
      if (response?.code === 201) {
        commit('ADD_DATA_GROUP', { groups: response.data })
        commit('SET_DATA_GROUP', { isLoad: false })
      }
      return response
    },
    async inviteForGroupMembers({ commit }, params) {
      commit('SET_DATA_GROUP', { isLoad: true })
      const response = await api.post(group.inviteGroupMembers, { body: params })
      commit('SET_DATA_GROUP', { isLoad: false })
      return response
    },
    async deleteGroup({ commit }, id) {
      const response = await api.delete(group.groupAction(id), { responseType: 'text' })
      if (response?.code === 204) commit('DELETE_DATA_GROUP', { groups: id })
      return response
    },
    async editGroup ({ commit }, params) {
      commit('SET_DATA_GROUP', { isLoad: true })
      const response = await api.patch(group.groupAction(params.id), { body: params.body })
      if (response?.code === 200 && response.data) {
        commit('SET_DATA_GROUP', { groupInfo: response.data })
        commit('SET_DATA_GROUP', { isLoad: false })
      }
      return response
    },
    async getGroupMembers ({ commit }, params) {
      commit('SET_DATA_GROUP', { isLoad: true })
      const response = await api.get(group.groupMembers + getQueryString(params))
      if (response?.code === 200) commit('SET_DATA_GROUP', { groupMembersList: response.data })
      commit('SET_DATA_GROUP', { isLoad: false })
      return response
    }
  },
  getters: {
    groupById: state => id => state.groups.find(group => group.id === id)
  }
}
