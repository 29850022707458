export default {
  state: () => ({
    language: localStorage.getItem('LOCALE') || 'uk',
    isLoadedApp: true
  }),
  mutations: {
    SET_DATA_APP (state, data) {
      state[Object.entries(data)[0][0]] = Object.entries(data)[0][1]
    }
  },
  actions: {
  },
  getters: {
  }
}
